///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ベース
*/
html {
  
  @include media_sphone {
    // フォントサイズ伸縮（ブラウザ幅375px基準で14px）
    font-size: math.div($sphone-font-size, 375px) * 100vw;
  }
  @include media_tablet {
    // フォントサイズ伸縮（ブラウザ幅768px基準で15px）
    //font-size: $tablet-font-size / $tablet * 100vw;
    // ↓変更
    // フォントサイズ固定（以前はデバイスサイズに合わせて相対サイズとしていましたが固定としました）
    font-size: $tablet-font-size;
  }
  @include media_desktop {
    // フォントサイズ固定
    font-size: $desktop-font-size;
  }
}

body {
  background: transparent;
  background-color: $clrBodyBg;
  margin: 0;

  font-size: 1rem;
  line-height: 1.5;
  color: $clrDefault;

  // フォントファミリーの定義は「_0.2.fonts.scss」にて
}

a,
a:not([href]):not([class]) {
  color: $clrLinkText;
  text-decoration: none;
  transform: .3s;
  cursor: pointer;

  &:hover {
    // hover時のスタイリングはここでは施しません（全てのa要素に対してニュートラルでありたいので部位ごとにスタイリングします）
  }

  &:focus {
    @include tab-focus;
  }

  &:active {
  }
}

pre {
  /*
  background-color: transparent;
  border: none;
  padding: 16px 0;
  */
}

p {
  margin-bottom: 1em;
}

h1,h2,h3,h4,h5,h6 {
  // 部位ごとにスタイリングを詰めたほうがいいと思いますのでここでは基本の共通定義のみ
  color: $clrDefaultDark;
  line-height: 1.2;
  font-weight: 500;
}

img {
  width: 100%;
  height: auto;
}

label {
  margin: 0;
  font-weight: normal;
}




/*
表組み（シンプル）

table要素、dl要素による表組レイアウト

商品詳細ページのフリーエリアや利用ガイド等の記事の中で差し込まれてくるシンプルな表組みについて基本スタイリングします。

特商や会員登録フォームといったコンテンツの表組みレイアウトは、_1.3.list.scssにて定義しています。
*/

/*
.table_basic 
*/
table.table_basic {
  width: 100%;
  table-layout: fixed;
  font-size: 0.933rem;
  margin: 2rem 0;
  @include borderTop;

  caption {
  }

  tr {
    @include borderBottom;

    &:nth-child(odd) {
      background-color: $clrBodyToneBg;
    }
  }

  th {
    padding: 0.5em;
    width: 10em;// コンテンツにより調整
    white-space: nowrap;
    vertical-align: top;
  }

  td {
    padding: 0.5em;
    width: auto;
    vertical-align: top;
  }
}

/* .caption */
.caption {
  margin: 0;
  padding: 0.5em 0;
  font-size: 0.933rem;
  color: $clrDefaultThin;
}



/*
dl要素をtable風に
*/
.dl_table {
  width: 100%;
  font-size: 0.933rem;
  margin: 2rem 0;

  .caption + & {
    margin-top: 0;
  }

  dl {
    margin: 0;
    @include borderBottom;

    &:nth-child(odd) {
      background-color: $clrBodyToneBg;
    }

    &:first-child {
      @include borderTop;
    }
  }

  dt {
    display: inline-block;
    margin: 0;
    padding: 0.5em;
    min-width: 10em;// コンテンツにより調整
  }

  dd {
    display: inline-block;
    margin: 0;
    padding: 0.5em;
  }
}



/*
dl要素レスポンシブ（タブレット・デスクトップ時はtable風で、スマホ時はdtとddを改行して表示）
*/
.dl_table.rwd {

  @include media_sphone {
    dl {
      margin: 0;
      border: none !important;
      background-color: transparent !important;
    }

    dt {
      display: block;
      background-color: $clrBodyToneBg;
    }

    dd {
      display: block;
      padding: .75em 0;// 少し余白を拡張
    }
  }
}



/*
ページスクロールでブロック要素をふわりと表示（共通エフェクト）

ページスクロールしてブロック領域が表示領域に入ったら、ふわりと表示させるためのエフェクトclassが付与されます（jeia_function.js）
準備としてHTML側にて、エフェクト出現をかけたいブロック要素にclass「.appearEffect」を記述しておきます。
エフェクトのスタイリングは基本、共通エフェクトとなりますが、個別のスタイリングにしたい場合はclass「.appearEffectCustom」で指定しておきます。
表示領域に入ったときに、共通エフェクトなら「.is-appear」を、個別スタイリングの場合は「.is-appearCustom」が付与されます。
ここでは共通エフェクトをスタイリングしています。（個別エフェクトはその適用要素のscssにてスタイリングを行います。）
*/
.appearEffect {
  transform: translate3d(0,75px,0);
  opacity: 0;
  transition: 1s;

  &.is-appear {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}



/*
ページスクロールで商品画像をエフェクト効果しながら表示

ページスクロールして商品画像等のアイテムが表示領域に入ったら、エフェクト効果しながら表示させるためのエフェクトclassが付与されます（jeia_function.js）
準備としてHTML側にて、エフェクト出現をかけたい要素（class「.image」や「.pic」など）にclass「.appearItem」を記述しておきます。
表示領域に入ったときに、エフェクトclass「.is-appear」が付与されます。

事前にスクリプトにより対象画像（img要素）に対して「.appearItemImage」要素で包括されるようにしています。
この包括要素に対してanimationがスタイリングされます。
包括要素を入れずにimg要素に対して直接アニメーションさせてしまうと、表示完了後にhoverアクション（transformを使った拡大表示等）が効かない事があります。
表示完了後に.appearItemを外したり、アニメーションを破棄すれば解決しますが、iOSでは解決しきらない事があったので包括要素を付ける仕様としました。

エフェクト効果のスタイリングは以下のようにパーツに分けてそれぞれにアニメーションさせています。

* パーツA：対象画像本体
* パーツB：グレーのマスク要素

*/
.appearItem {
	position: relative;
	overflow: hidden;
  visibility: hidden;

  // 上→下
  $direction-start: '0, -100%, 0';
  $direction-end:   '0, 0, 0';
  $direction-out:   '0, 100%, 0';
  // 左→右
  //$direction-start: '-100%,0,0';
  //$direction-end:   '0,0,0';
  //$direction-out:  '100%,0,0';
  $easing: 'ease-in-out';//'cubic-bezier(0.165, 0.84, 0.44, 1)';

  /* パーツA: 対象画像本体 */
  .appearItemImage {
    transform: translate3d( #{$direction-start} );
  }

  /* パーツB: マスク */
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #eee;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate3d( #{$direction-start} );
  }

  /* パーツC: ロゴ */

  /* アクティブ化表示状態 */
  &.is-appear {
    visibility: visible;

    /* パーツA: 商品画像本体 */
    .appearItemImage {
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: #{$easing};
      animation-delay: 0.3s;/*長くとると動きが増える*/
      animation-name: appearAnimation_a;
    }
    @keyframes appearAnimation_a {
      0% {
        transform: translate3d( #{$direction-start} );
      }
      100% {
        transform: translate3d( #{$direction-end} );
      }
    }

    /* パーツB: マスク */
    &:before {
      visibility: hidden;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      animation-timing-function: #{$easing};
      animation-name: appearAnimation_b;
    }
    @keyframes appearAnimation_b {
      /* [0%] IN→ [30%] Stay [70%] OUT→ [100%] */
      0% {
        visibility: visible;
        transform: translate3d( #{$direction-start} );
      }
      30% {
        transform: translate3d( #{$direction-end} );
      }
      70% {
        transform: translate3d( #{$direction-end} );
      }
      100% {
        transform: translate3d( #{$direction-out} );
      }
    }

    /* パーツC: ロゴ */
  }  
}
