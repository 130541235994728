///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ピックアップカテゴリ

トップページで使用されているピックアップブロックのスタイルです。

ピックアップしたいカテゴリ（もしくは特集コンテンツ）のヘッドイメージとタイトル面積を大きくとり、
またピックアップ商品も2つずつスライド表示します。

メモ：
スタイリングがやや煩雑となりますので、まずデスクトップ用にスタイリングを施し、あとからスマホ・タブレット用に上書き調整スタイリングをしています。
左右交互にするスタイリングも同様にあとから調整を施します。
*/

/* ラッパー */
.ec-pickupCategoryRoleWrap {
  margin: 0;
  padding: 0;
}

/* ピックアップカテゴリ単位 */
.ec-pickupCategoryRole {
  display: flex;

  /* カテゴリヘッド */
  & &__head {
    flex: 0 0 50%;
    order: 2;

    // for IE11
    @include hack-ie11 {
      max-width: 50%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    /* ヘッド画像（background-image）はcategory.twigで指定しています */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 5vw;
    box-sizing: border-box;

    .headtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,.66);
      color: #fff;

      font-family: $ff-robotoslab;
      font-size: 2.6rem;
      line-height: 1.2;

      // 領域サイズはタイトルテキストによって調整が必要かもしれません
      width: 30vw;
      max-width: 340px;
      height: 30vw;
      max-height: 340px;
      box-sizing: border-box;
      padding: 1em;

      span {
        text-align: center;
      }
    }

    position: relative;// くちばし用
    
    /* くちばし */
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: solid 1.5rem transparent;
      border-left-color: $clrBodyBg;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0,-50%);
    }
  }

  /* 商品リストエリア */
  & &__list {
    flex: 0 0 50%;
    order: 1;

    // for IE11
    @include hack-ie11 {
      max-width: 50%;
    }

    /*
    flexアイテムの中でslickを使うとレイアウトが崩れる対策
    slick要素を含むflexアイテムに、min-height:0; min-width:0;で対策します。
    ※そもそも、flexでなくfloatで組めば問題は起こりませんがあえてflexで組んでみました。
    */
    min-height:0;
    min-width: 0;

    box-sizing: border-box;
    padding: 5vw;

    /* 商品リストのタイトルと説明文*/
    .listHeading {
      margin-bottom: 2rem;

      h2 {
        font-family: $ff-robotoslab;
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        margin-bottom: 1rem;

        @include media_desktop {
          font-size: 1.85rem;
        }
      }

      .description {
        font-size: 0.933rem;
        color: $clrDefaultThin;
      }
    }

    /* デスクトップ時のprev,nextボタンを少し小さめに */
    @include media_desktop {
      .slick-arrow {
        font-size: 2rem;
      }
    }
  }

  // スマホ用調整
  @include media_sphone {
    flex-flow: row wrap;

    & &__head {
      flex-basis: 100%;
      order: 1;

      justify-content: center !important;
      padding: 40px $padding-sphone;

      .headtitle {
        font-size: 1.5rem;
        width: 50vw;
        height: auto;
      }

      &:after {
        display: none;
      }
    }

    & &__list {
      order: 2;
      flex-basis: 100%;
      padding: $padding-sphone;

      // prev,nextボタンを外に配置するのでそのための余白を確保します
      .listHeading {
        margin-bottom: 40px;
      }
    }
  }

  // タブレット用調整
  @include media_tablet {
    & &__head {
      flex-basis: 33.3%;
      padding: 0;

      .headtitle {
        font-size: 1.8rem;
        width: 100%;
        max-width: 100%;
        min-height: 33.3vw;
        height: auto;
        max-height: auto;
      }
    }

    & &__list {
      flex-basis: 66.6%;
      padding: $padding-tablet;

      // prev,nextボタンを外に配置するのでそのための余白を確保します
      .listHeading {
        margin-bottom: 40px;
      }
    }
  }
}

/*
カテゴリヘッドと商品リストを左右交互にします
ここまでのスタイリングは、カテゴリヘッドを右に、商品リストを左にしたスタイリングになっています（1番目）。
これを逆にするための上書き調整をします。（2番目）
*/
.ec-pickupCategoryRole:nth-child(even) {

  /* カテゴリヘッド */
  .ec-pickupCategoryRole__head {
    order: 1;

    /* くちばし */
    &:after {
      left: auto;
      right: 0;
      border-left-color: transparent;
      border-right-color: $clrBodyBg;
    }

  }

  /* 商品リスト */
  .ec-pickupCategoryRole__list {
    order: 2;
  }
}
