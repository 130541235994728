///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
slick

トッププロモーションや商品ビジュアル画像、商品リストのスライド表示にはEC-CUBE4標準と同じく「slick」を使用しています。

slickを適用する要素は共通してclass「.ec-slickContainer」を設置してその要素に対して適用させるようにしています。

slick適用要素は、slickの準備ができるまではエリアを非表示としておき、準備が整ってからフェードイン表示としています。
slickの準備が完了するまでは画像が一瞬大きく表示されてしまう事がありますのでそれを見せないための処置です。

メモ：
slickドットとslickアロー（prev,next）と両方適用させると、アローボタンは上下中央よりずれてしまいます。
*/
.ec-slickContainer {
  // slick準備整うまでは非表示
  visibility: hidden;
  opacity: 0;
  filter: blur(5px);// ぼかしエフェクト
  transition: 1s;

  // slick準備完了で表示開始
  &.slick-initialized.is-readiness {
    visibility: visible;
    opacity: 1;
    filter: blur(0);
  }
}



/*
slick用パーツ

slick用パーツの共通スタイリングです。

* prev,nextボタン
* ドットボタン
* slickガター
* prev, nextボタン（slick領域外側配置）
* キャプション表示
*/



/*
prev,nextボタン

ボタンを画像に被せて左右に配置するるタイプのスタイリングです。
もしボタンをslick適用領域の外側に配置する場合は後述の「slickArrowOuter」を適用してください。
*/
.slick-slider {
  position: relative;// prev,nextボタン用

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    padding: 10px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;

    opacity: .5;
    transition: .2s;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    &:hover {
      opacity: 1;
    }

    i {
      display: block;
    }
  }
}


/*
ドットボタン
*/
.slick-slider {

  .slick-dots {
    $size: 15px;

    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    padding-top: $size * 1.5;

    li {
      display: block;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: $noactive-color;
      cursor: pointer;

      button {
        display: none;
      }

      &.slick-active {
        background-color: $active-color;
      }

      & + li {
        margin-left: $size * 0.7;
      }
    }
  }
}


/*
slickガター

slick適用内のアイテム間にガター（間隔）を設けます。

slickを適用する要素（基本は.ec-ec-slickContainer）に併せてclass「slickGutter」を記述しておきます。

※なお、レスポンシブでデバイス事にガター幅を可変できるようにmixin化しています。

メモ：
普通に、.slick-slide要素にマージンを設定し、先祖要素である.slick-listにネガティブマージンを設定して調整します。
しかし、それだけではスライド時に左側にわずかに商品アイテムがはみ出した形で見えてしまいます。これを防ぐ為には、
slick適用の要素（基本は.ec-ec-slickContainer）に「overflow:hidden;」すれば解決しますが、
prev,nectボタンをslick領域の外側に配置したい場合があり（position:absolute;で外に出しています）、overflowだと
この外側に配置したpreve,nextボタンが隠されてしまい見えない事になります。
ですので、ネガティブマージンをかけた.slick-list要素に「clip-path」プロパティを使い表示領域をクリップすることで対応しています。

※IE11、Edgeはclip-path非対応なのでクリップパスは適用されず結果、スライド時に左側が少しはみ出して見えます（2019/09）
*/
@mixin slickGutter($gutter: 10px) {

  .slick-list {
    margin-left: $gutter * -1;// ネガティブマージン
    clip-path: inset( 0 0 0 $gutter );// clip-pathを使ってはみ出す領域をクリップ

    .slick-slide {
      margin-left: $gutter;
    }
  }
}

.slickGutter {
  // ガターを10px
  @include slickGutter(10px);

  // デスクトップ時はガター20px
  @include media_desktop {
    @include slickGutter(20px);
  }
}



/*
prev, nextボタン（slick領域外側配置）

slick適用領域の外側にprev,nextボタンを配置するタイプのスタイリングです。
スマホ・タブレット時はslick領域の上部の外、右側につめて配置させます。

slickを適用する要素（基本は.ec-slickContainer）に併せてclass「slickArrowOuter」を記述しておきます。
*/
.slickArrowOuter {

  .slick-arrow {
    color: #666;

    &:hover {
      color: #333;
    }

    // スマホ・タブレット時はslick領域の上部の外、右側につめて配置
    @media only screen and ( max-width: $desktop - 1 ) {
      $size: 35px;// ボタンサイズ

      display: flex;
      justify-content: center;
      align-items: center;
      width: $size;
      height: $size;

      position: absolute;
      top: auto;
      bottom: 100%;
      transform: translate(0,0);
      font-size: $size * 0.7;

      &.slick-prev {
        left: auto;
        right: $size;
      }

      &.slick-next {
        left: auto;
        right: 0;
      }
    }

    // デスクトップ時はslick領域の左右外側にボタン配置
    @include media_desktop {
      width: 1em;// for IE11

      &.slick-prev {
        left: auto;
        right: 100%;
      }

      &.slick-next {
        right: auto;
        left: 100%;
      }
    }
  }
}



/*
slick キャプション表示

slick適用の各アイテムの中に「.caption」要素（説明文）を記述しておくと画像に被さる形でテキストが表示されます。

商品詳細ページでは、slickを適用する画像ビジュアル部分が動的に出力されますのでキャプション要素を追加できませんが、
フリーエリア内にてキャプション要素を記述しておき、javascirptで各キャプションを各画像アイテム内に挿入させて実装しています。
*/
.slick-slider {

  .slick-slide {
    position: relative;

    .caption {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      font-size: .933rem;
      color: #000;
      background-color: hsla(0, 0%, 100%, 0.5);
      padding: 1em;
      box-sizing: border-box;

      visibility: hidden;
      opacity: 0;
      transform: translate3d(0,100%,0);
      transition: .5s;
      transition-delay: .5s;
    }

    &.slick-current {

      .caption {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
  }
}



/*
トップページ ヘッドプロモーション

slickを使用してスライド表示します。

slickキャプション表示と、ドットボタンについては、上記のslick用パーツの定義を上書き調整してスタイリングしています。
*/
.ec-headPromotionRole {

  .ec-slickContainer {

    /* slickパーツ定義したキャプション表示を上書きしてヘッドプロモーション用に調整 */
    .caption {

      color: #fff;
      background-color: transparent;
      padding: 0;
      font-family: $ff-en;
      line-height: 1.3;
      transform: translate3d(0,1em,0);
      transition: .5s;
      transition-delay: 1s;

      @include media_sphone {
        padding-left: $padding-sphone;
        padding-bottom: 3rem;
        font-size: 1.5rem;
      }

      @include media_tablet {
        padding-left: $padding-tablet;
        padding-bottom: 3rem;
        font-size: 1.5rem;
      }

      @include media_desktop {
        padding-left: $padding-desktop;
        padding-bottom: 5rem;
        font-size: 1.75rem;
      }
    }

    /* ドットボタンの形状と位置を上書き調整 */
    .slick-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1.5rem;

      li {
        width: 40px;
        height: 7px;
        border-radius: 0;
      }
    }
  }
}
