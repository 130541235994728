///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
アイキャッチ

トップページで使用されているアイキャッチブロックのスタイルです。
*/
.ec-eyecatchRole {

  // 基本レイアウトをデバイス事にスタイリング

  @include media_sphone {
    margin-top: 0;
    margin-bottom: $padding-sphone;

    & &__image {
      margin-left: $padding-sphone * -1;
      margin-right: $padding-sphone * -1;

      img {
        width: 100vw;
      }
    }

    & &__intro {
      margin-top: $padding-sphone;
    }  
  }

  @include media_tablet {
    margin-top: 0;
    margin-bottom: $padding-tablet;

    & &__image {
      margin-left: $padding-tablet * -1;
      margin-right: $padding-tablet * -1;

      img {
        width: 100vw;
      }
    }

    & &__intro {
      margin-top: $padding-tablet;
    }  
  }

  @include media_desktop {
    display: flex;
    margin-top: $padding-desktop * 2;
    margin-bottom: $padding-desktop * 2;

    & &__image {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    & &__intro {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: $padding-desktop;
      box-sizing: border-box;
  
      /* ボタンを底配置するためのflex */
      display: flex;
      flex-flow: column;
    }
  
    & &__btn {
      /* 底配置 */
      margin: auto 0 0;
    }  
  }

  & &__introEnTitle {
    font-size: 0.933rem;
  }

  & &__introTitle {
    font-size: 1.5rem;
    font-weight: 500;
  }

  & &__introDescription {
    font-size: 1rem;
    line-height: 2;
    margin-bottom: 2em;
    text-align: justify;
  }
}
