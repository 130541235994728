///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
おすすめ商品管理プラグイン

※当テンプレートではカスタマイズした「Block/recommend_product_block.twig」を使用しています。
*/
.ec-recommendProductRole {

  @include container;
  max-width: 100%;
  background-color: transparent;

  @include media_sphone {
    padding-top: $padding-sphone;
    padding-bottom: $padding-sphone;
  }

  @include media_tablet {
    padding-top: $padding-tablet;
    padding-bottom: $padding-tablet;
  }

  @include media_desktop {
    padding: $padding-desktop 60px;// slickのprev,nextボタン分の余白を多めに取っておく
  }

  & &__listInner {
    max-width: 1920px;
    margin: 0 auto;
  }

  // 商品アイテムスタイルを上書き（商品一覧スタイルは13.2.shelf.scssにて定義されています。当ブロック用に上書き調整します）
  .ec-productItemRole {

    .ec-productItemRole__content {
    }

    // おすすめ商品コメント
    .ec-productItemRole__recommendComment {
      font-size: 0.933rem;
      line-height: 1.7;
      margin: 1em 0;
      text-align: justify;
      word-wrap: break-word;

      // 行数制限
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;

      @include media_sphone {
        font-size: 0.857rem;
        line-height: 1.4;
      }
    }
  }
}
