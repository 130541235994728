/*
///////////////////////////////////////////////////////////////////////

This template is part of EC-CUBE Design template.

Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.

http://www.jeia.co.jp/

For the full copyright and license information, please view the LICENSE
file that was distributed with this source code.

本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。

※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
別途、ご利用費用をお支払いただく必要がございます。
※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
※本テンプレートは、すべての設置環境で動作する事は保証しておりません。

///////////////////////////////////////////////////////////////////////
*/
@use "sass:math";

@import url('../../../../../node_modules/normalize.css/normalize.css');
@import "mixins/variables";
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

@import "mixins/animation";
@import "mixins/btn";
@import "mixins/clearfix";
@import "mixins/forms";
@import "mixins/media";
@import "mixins/projects";

@import "component/0.1.base";
@import "component/0.2.fonts";
@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/11.5.head_information";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/12.9.calendar";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/14.4.product_zoom";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";
@import "project/23.1.help";

// プラグイン
@import "project/30.0.plugin";
@import "project/30.1.recommend_product";
@import "project/30.2.related_product";
@import "project/30.3.product_review";
